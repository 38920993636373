// import { prepareThumbSlider } from '../utils/prepareFns';

export default {
	init() {
		// JavaScript to be fired on all pages
		// prepare_navbar();
		// prepareThumbSlider();
		// prepareFilterForm();
		prepare_header();
		prepare_show_more_news();
		mobile_sections();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

function prepare_navbar() {
	const body = document.querySelector('body');
	const menuBtn = document.querySelector('.menu-button');
	const header = document.querySelector('header.header');
	const nav = header.querySelector('nav#nav-main');

	menuBtn.addEventListener('click', () => {
		if (header.dataset.active === 'true') {
			header.dataset.active = 'false';
			nav.setAttribute('aria-hidden', true);
			body.classList.remove('noscroll');
		} else {
			header.dataset.active = 'true';
			nav.setAttribute('aria-hidden', false);
			body.classList.add('noscroll');
		}
	});

	nav.querySelectorAll('.menu-item-has-children').forEach((item) => {
		let link = item.querySelector('.nav-main-link');
		let subnav = item.querySelector('.sub-nav');
		let closeIcon = item.querySelector('.icon');

		// clone the subnav & insert as last child of header element
		// this will be used for desktop. needs to be at same level as top nav so
		// it can be stacked below it for slide-in effect
		let desktopSubnav = header.insertBefore(subnav.cloneNode(true), null);

		const clickCallback = (e) => {
			if (subnav.getAttribute('aria-hidden') === 'true') {
				e.preventDefault();
				subnav.setAttribute('aria-hidden', false);
				closeIcon.setAttribute('aria-hidden', false);
			}
		};

		link.addEventListener('click', clickCallback);

		item.addEventListener('mouseover', (e) => {
			if (e.view.innerWidth > 1024) {
				item.classList.add('hovered');
				link.removeEventListener('click', clickCallback);
				desktopSubnav.setAttribute('aria-hidden', false);
			}
		});

		item.addEventListener('mouseout', (e) => {
			let rt = e.relatedTarget;

			if (rt !== desktopSubnav && !desktopSubnav.contains(rt)) {
				if (desktopSubnav.getAttribute('aria-hidden') === 'false') {
					desktopSubnav.setAttribute('aria-hidden', true);
				}
				if (item.classList.contains('hovered')) {
					item.classList.remove('hovered');
				}
			}
		});

		desktopSubnav.addEventListener('mouseout', (e) => {
			let rt = e.relatedTarget;

			if (!desktopSubnav.contains(rt) && rt !== item && !item.contains(rt)) {
				if (desktopSubnav.getAttribute('aria-hidden') === 'false') {
					desktopSubnav.setAttribute('aria-hidden', true);
				}
				if (item.classList.contains('hovered')) {
					item.classList.remove('hovered');
				}
			}
		});

		closeIcon.addEventListener('click', () => {
			subnav.setAttribute('aria-hidden', true);
			closeIcon.setAttribute('aria-hidden', true);
		});
	});
}

function prepareFilterForm() {
	const submitBtn = document.querySelector('.filter-btn');

	if (!submitBtn) {
		return;
	}

	submitBtn.addEventListener('click', (e) => {
		e.preventDefault();

		const baseUrl = '/apartments-living/available-apartments';
		const anchor = '#apartment-listings';

		const serviceVal = document.querySelector('select[name="level-of-service"]')
			.value;
		const planVal = document.querySelector('select[name="floor-plan"]').value;

		location.href =
			serviceVal === 'all' && planVal === 'all'
				? `${baseUrl}`
				: serviceVal === 'all'
				? `${baseUrl}/floor-plan/${planVal}/${anchor}`
				: planVal === 'all'
				? `${baseUrl}/service-level/${serviceVal}/${anchor}`
				: `${baseUrl}/service-level/${serviceVal}/floor-plan/${planVal}/${anchor}`;
	});
}

function prepare_header() {
	var nav = document.getElementById('page-nav');
	var cursor = 0;
	var precursor = 0;

	window.addEventListener('scroll', function (e) {
		cursor = window.pageYOffset || document.documentElement.scrollTop;
		nav.classList.toggle('nav-show', cursor < precursor);
		if (cursor === 0) nav.classList.add('nav-show');
		precursor = cursor;
	});
}

function mobile_sections() {
	var groupItems = document.querySelectorAll('.group-item');
	var i, j;
	for (i = 0, j = groupItems.length; i < j; i++) {
		groupItems[i].addEventListener('click', function (e) {
			this.classList.toggle('group-item-open-label');
		});
	}

	var profileItemImages = document.querySelectorAll('.profile-item-image');
	for (i = 0, j = profileItemImages.length; i < j; i++) {
		profileItemImages[i].addEventListener('click', function (e) {
			this.parentNode.parentNode.parentNode.parentNode.classList.toggle(
				'profile-item-open'
			);
		});
	}
}

function prepare_show_more_news() {
	let button = document.getElementById('show-more-news');

	if (!button) {
		return;
	}

	button.addEventListener('click', (event) => {
		let container = document.getElementsByClassName('news-group');
		container[0].classList.add('news-group-all');
	});
}
